import styled from 'styled-components';

const CookieConsentStyle = styled.div`
	--padding: var(--sp5x);
	--paddingT16: var(--sp2x);
	--paddingT32: var(--sp4x);
	--width: calc(calc(100% - var(--contPaddingLR) * 2) / 2);

	width: var(--width);
	padding: var(--padding);
	backdrop-filter: blur(44px);
	-webkit-backdrop-filter: blur(44px);
	border-radius: var(--sp2x);
	border: 1px solid var(--white);
	position: fixed;
	left: var(--contPaddingLR);
	transform: translateY(200%);
	bottom: calc(var(--contPaddingLR) / 2);
	z-index: 100000;

	.padding-top-16 {
		padding-top: var(--paddingT16);
	}

	.padding-left-16 {
		margin-left: var(--paddingT16);
	}

	.padding-top-24 {
		padding-top: var(--paddingT32);
	}

	.cookie-buttons-wrapper {
		align-items: center;
	}

	.close-cookie-btn {
		text-decoration: underline;
		color: var(--white);
		font-size: var(--h4);
		font-family: var(--golos_text);
		font-weight: 500;
		text-transform: uppercase;
		cursor: pointer;

		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	//! 1024
	@media only screen and (max-width: 1024px) {
		--width: calc(100% - (var(--contPaddingLR) * 2));
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--width: calc(100% - (var(--contPaddingLR) * 2));
		--padding: var(--sp2x);
	}
`;

export default CookieConsentStyle;

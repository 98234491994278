import styled from 'styled-components';

const PopupStyle = styled.div`
	--popupMaxWidth: 70.65vw;
	--popupPadTopBottom: var(--sp11x);
	--popupPadLeftRight: var(--sp23x);
	--closePosTopRightSize: var(--sp7x);

	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	width: 100vw;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100000;

	.popup-content {
		max-height: calc(100dvh - var(--headerFixedHeight));
	}

	.popup-background {
		-webkit-backdrop-filter: blur(44px);
		backdrop-filter: blur(44px);
		pointer-events: all;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	@keyframes popupOpenAnim {
		from {
			transform: scale(0.8);
			opacity: 0;
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}

	.popup-content-container {
		width: var(--popupMaxWidth);
		height: 100%;
		max-height: calc(100dvh - var(--headerFixedHeight));
		display: flex;
		align-items: center;
		pointer-events: all;
		animation: popupOpenAnim var(--trTime) ease-in-out forwards;

		.popup-content-wrap {
			position: relative;
			width: var(--popupMaxWidth);
			height: fit-content;
			max-height: calc(100dvh - var(--headerFixedHeight));
			padding: var(--popupPadTopBottom) var(--popupPadLeftRight);
			background: transparent;
			box-shadow: 2px 6px 20px rgba(0, 0, 0, 0.1);
			opacity: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			border: 1px solid var(--white);
			border-radius: var(--sp1x);

			&::-webkit-scrollbar {
				display: none;
			}

			.close-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: var(--closePosTopRightSize);
				right: var(--closePosTopRightSize);
				z-index: 2;
				cursor: pointer;

				i {
					font-size: var(--h2);
					color: var(--white);
				}
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--popupMaxWidth: 71.15vw;
		--popupPadTopBottom: var(--sp10x);
		--popupPadLeftRight: var(--sp17x);
		--closePosTopRightSize: var(--sp5x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--popupMaxWidth: calc(100vw - var(--contPaddingLR) * 2);
		--popupPadTopBottom: var(--sp9x);
		--popupPadLeftRight: var(--sp14x);
		--closePosTopRightSize: var(--sp5x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--popupMaxWidth: calc(100vw - var(--contPaddingLR) * 2);
		--popupPadTopBottom: var(--sp8x);
		--popupPadLeftRight: var(--sp12x);
		--closePosTopRightSize: var(--sp5x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--popupMaxWidth: calc(100vw - var(--contPaddingLR) * 2);
		--popupPadTopBottom: var(--sp8x);
		--popupPadLeftRight: var(--sp12x);
		--closePosTopRightSize: var(--sp5x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--popupMaxWidth: calc(100vw - var(--contPaddingLR) * 2);
		--popupPadTopBottom: var(--sp9x);
		--popupPadLeftRight: var(--sp3x);
		--closePosTopRightSize: var(--sp3x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--popupMaxWidth: calc(100vw - var(--contPaddingLR) * 2);
		--popupPadTopBottom: var(--sp9x);
		--popupPadLeftRight: var(--sp2x);
		--closePosTopRightSize: var(--sp2x);
	}
`;

export default PopupStyle;

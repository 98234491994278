'use client';

import { useState, useEffect, useRef, useCallback } from 'react';
import Cookie from 'js-cookie';
import gsap from 'gsap';

//* Style
import CookieConsentStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';

const CookieConsent = () => {
	//! Ref
	const popupRef = useRef();

	//! State
	const [active, setActive] = useState(false);

	//! Did Mount
	useEffect(() => {
		const cpCookie = Cookie.get('accepted');

		if (!cpCookie) {
			setActive(true);
		}
	}, []);

	useEffect(() => {
		if (active) {
			gsap.to(popupRef.current, {
				yPercent: 0,
				x: 0,
				y: 0,
				force3D: true,
				delay: 3.3,
				duration: 0.7,
				ease: 'power1.out',
			});
		}
	}, [active]);

	//! Handle accept click
	const handleAcceptClick = useCallback(() => {
		Cookie.set('accepted', true);

		gsap.to(popupRef.current, {
			yPercent: 200,
		}).then(() => setActive(false));
	}, [active]);

	//! Handle close click
	const handleCloseClick = useCallback(() => {
		gsap.to(popupRef.current, {
			yPercent: 200,
		}).then(() => setActive(false));
	}, [active]);

	return active ? (
		<CookieConsentStyle ref={popupRef}>
			<Container
				row
				full
				className={'cookie-consent'}>
				<div className={`col-12`}>
					<Text
						className={`h4 white font-zen-dots-regular`}
						text={'cookies'}
					/>
				</div>

				<div className={`col-12`}>
					<Text
						className={`h6 white font-golos-text-regular padding-top-16`}
						text={'cookiesDescription'}
					/>
				</div>

				<div className={`col-12 d-flex padding-top-24 cookie-buttons-wrapper`}>
					<Button
						text={'accept'}
						className={'primary'}
						onClick={handleAcceptClick}
					/>

					<Text
						className={`close-cookie-btn padding-left-16`}
						onClick={handleCloseClick}
						text={'deny'}
					/>
				</div>
			</Container>
		</CookieConsentStyle>
	) : null;
};

export default CookieConsent;

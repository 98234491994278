'use client';

//* Config
import config from '@/helpers/_config';

//* Components
import CustomLink from '../CustomLink';
import Text from '@/components/global/Text';
import Container from '@/components/global/Container';

//* Style
import FooterStyle from './style';
import { withLanguageContext } from '@/context';

const AppFooter = ({ translate }) => {
	return (
		<FooterStyle className={`footer-container`}>
			<Container
				row
				className={'opacity07'}>
				<div className={`col-dxl-9 col-ds-8 col-m-12`}>
					<div className={'d-flex'}>
						<Text className={`h5 white font-golos-text-regular`}>
							<CustomLink
								content={translate('privacyPolicy')}
								url={config.routes.privacyPolicy.path}
							/>
						</Text>

						<Text
							className={`h5 white font-golos-text-regular mL-24`}
							text={'symbolFooter'}
						/>

						<Text className={`h5 white font-golos-text-regular mL-24`}>
							<CustomLink
								content={translate('termsOfUse')}
								url={config.routes.termsOfUse.path}
							/>
						</Text>
					</div>
				</div>

				<div className={`col-dxl-3 col-ds-4 col-m-12`}>
					<Text className={`h5 white font-golos-text-regular footer-text`}>
						{' '}
						{translate('symbolBy')}
						{new Date().getFullYear()}
						{translate('nameENA')}
					</Text>
				</div>
			</Container>
		</FooterStyle>
	);
};

export default withLanguageContext(AppFooter, ['translate']);

import styled from 'styled-components';

const FooterStyle = styled.footer`
	--margin: var(--sp3x);
	--paddingBottom: var(--sp8x);

	&.footer-container {
		padding-bottom: var(--paddingBottom);
	}

	.footer-text {
		text-align: right;
	}

	.mL-24 {
		margin-left: var(--margin);
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--paddingBottom: var(--sp10x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--paddingBottom: var(--sp7x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--paddingBottom: var(--sp6x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--paddingBottom: var(--sp6x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--paddingBottom: var(--sp4x);
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--paddingBottom: var(--sp4x);

		.footer-text {
			text-align: left;
			margin-top: var(--margin);
		}
	}
`;

export default FooterStyle;

'use client';

import { useRef } from 'react';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Config
import config from '@/helpers/_config';

//* Style
import HeaderStyle from './style';

//* Components
import ScrollActive from '../ScrollActive';
import Image from '@/components/global/Image';
import PopupContactUs from '../PopupContactUs';
import Button from '@/components/global/Button';
import CustomLink from '@/components/global/CustomLink';

const AppHeader = ({ openPopup, translate }) => {
	//! Refs
	const buttonRef = useRef();
	const titleRef = useRef();

	return (
		<HeaderStyle className={`header-container`}>
			<ScrollActive
				animY
				delay={0.8}
				refsContainer={[titleRef]}>
				<div className='col-3 overflow-hidden'>
					<div
						className={`logo-link translate-y--100`}
						ref={titleRef}>
						<CustomLink
							ariaLabel={'Logo Link'}
							url={config.routes.home.path}>
							<Image
								className={'contain'}
								src={'/images/logo.svg'}
							/>
						</CustomLink>
					</div>
				</div>
			</ScrollActive>

			<div className='col-5' />

			<ScrollActive
				animY
				delay={1}
				refsContainer={[buttonRef]}>
				<div className='col-4 overflow-hidden'>
					<div
						ref={buttonRef}
						className='buttons-wrap translate-y--110'>
						<Button
							text={translate('requestDemo')}
							className={'secondary'}
							onClick={() => openPopup(<PopupContactUs />)}
						/>
					</div>
				</div>
			</ScrollActive>
		</HeaderStyle>
	);
};

export default withLanguageContext(withUIContext(AppHeader, ['openPopup']), ['translate']);

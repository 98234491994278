import styled from 'styled-components';

const HeaderStyle = styled.header`
	--logoWidth: var(--sp23x);
	--logoHeight: var(--sp9x);
	--contPaddingTB: var(--sp5x);

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: transparent;
	padding: var(--contPaddingTB) var(--contPaddingLR);
	z-index: 11;

	.logo-link {
		width: var(--logoWidth);
		cursor: pointer;
	}

	.buttons-wrap {
		display: flex;
		justify-content: end;

		.btn {
			button {
				-webkit-backdrop-filter: blur(5px);
				backdrop-filter: blur(5px);
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--logoWidth: var(--sp17x);
		--contPaddingTB: var(--sp4x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--logoWidth: var(--sp17x);
		--contPaddingTB: var(--sp4x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--logoWidth: var(--sp16x);
		--contPaddingTB: var(--sp4x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--logoWidth: var(--sp16x);
		--contPaddingTB: var(--sp3x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--logoWidth: var(--sp16x);
		--contPaddingTB: var(--sp3x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--logoWidth: var(--sp10x);
		--contPaddingTB: var(--sp2x);
	}
`;

export default HeaderStyle;

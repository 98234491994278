import(/* webpackMode: "eager" */ "/app/src/components/global/AppFooter/index.js");
import(/* webpackMode: "eager" */ "/app/src/components/global/AppHeader/index.js");
import(/* webpackMode: "eager" */ "/app/src/components/global/CookieConsent/index.js");
import(/* webpackMode: "eager" */ "/app/src/components/global/Popup/index.js");
import(/* webpackMode: "eager" */ "/app/src/components/global/ScrollWrapper/index.js");
import(/* webpackMode: "eager" */ "/app/src/context/providers/DataProvider.js");
import(/* webpackMode: "eager" */ "/app/src/context/providers/LanguageProvider.js");
import(/* webpackMode: "eager" */ "/app/src/context/providers/UIProvider.js");
import(/* webpackMode: "eager" */ "/app/src/helpers/_useActiveSection.js");
import(/* webpackMode: "eager" */ "/app/src/libs/styled-registry.js");
import(/* webpackMode: "eager" */ "/app/src/styles/icons/style.css")